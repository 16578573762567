import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../theme/mainTheme.js'
import styled from 'styled-components'
import GlobalStyle from '../theme/GlobalStyle.js'
import { ThemeProvider } from 'styled-components'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useFormik } from 'formik'
import Input from '../components/atoms/input.js'
import Button from '../components/atoms/Button'
import SmallHeader from '../components/atoms/SmallHeader.js'
import Paragraph from '../components/atoms/paragraph.js'
import { set, ref } from 'firebase/database'
import { auth, database } from '../firebase/firebase.js'
import spursLogo from '../assets/graphs/spurs-140.webp'
import nbaLogo from '../assets/graphs/nba-140.webp'
import appLogo from '../assets/graphs/sochan-120.webp'
import { Helmet } from 'react-helmet';

const DivSquare = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
`

const VerySmallHerader = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`

const FormImg = styled.img`
	width: 60px;
	margin-top: 10px;
`

const FormLogos = styled.div`
	display: flex;
	justify-content: space-around;
`

const FormFooter = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
`

const HeadPart = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 0px 30px 0px;
	background-color: ${props => props.theme.white};
	width: 300px;
	@media (min-width: 768px) {
		width: 500px;
	}
`

const RegisterForm = styled.form`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 30px 0px 30px 0px;
	@media (min-width: 768px) {
		margin-left: 30px;
		padding: 0px 0px 30px 0px;
	}
`

const RegisterContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 30px 30px 0px 30px;
	border-radius: 25px;
	background-color: ${props => props.theme.black};
	justify-content: center;
	align-items: center;
	@media (min-width: 768px) {
		flex-direction: row;

	}
`

const ImgHeader = styled.img`
margin-bottom: 0px;
@media (min-width: 768px) {
	margin-bottom: 30px;
	}
`

const RegistrationPage = () => {
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			password: '',
		},
		validate: values => {
			const errors = {}
			if (!values.email) {
				errors.email = 'Required'
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = 'Invalid email address'
			}
			return errors
		},
		onSubmit: (values, { resetForm }) => {
			const { email, password, name } = values

			createUserWithEmailAndPassword(auth, email, password)
				.then(userCredential => {
					const user = userCredential.user

					// Aktualizacja profilu użytkownika
					updateProfile(user, {
						displayName: name,
						photoURL:
							'https://firebasestorage.googleapis.com/v0/b/favnote-a09d2.appspot.com/o/profilePictures%2FR4498siYdXSYWt9rUk8oRGdvTso1?alt=media&token=19bc96af-007e-4e21-b90c-7a4e2362ac84',
					})

					// Zapis imienia użytkownika i jego userId w Realtime Database
					const usersRef = ref(database, 'names/' + user.uid)
					set(usersRef, {
						name: name,
					})

					navigate('/', { state: { dataToSend: user.displayName } })
				})
				.catch(error => {
					navigate('/failure', { state: { dataToSend: error.message } })
				})
				.finally(() => {
					resetForm()
				})
		},
	})

	const navigate = useNavigate()

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Helmet>
          <title>Please register your account</title>
		  <meta name="description" content={`Here you can register your account in 10 portal.`} />
          <meta name="keywords" content={`San Antonio, Górnik Wałbrzych, Basketball, Jeremy Sochan, nba, scores, video, twitters`} />
        </Helmet>

			<DivSquare>
				<HeadPart>

					<VerySmallHerader>
					<SmallHeader>Create an account to access all the features of the app.</SmallHeader>
					</VerySmallHerader>
				</HeadPart>
				<RegisterContainer>
					<ImgHeader src={appLogo} alt='Spurs Logo' />
					<RegisterForm onSubmit={formik.handleSubmit}>
						<Input
							id='name'
							name='name'
							type='name'
							onChange={formik.handleChange}
							value={formik.values.name}
							placeholder={'Add your name'}
						/>
						<Input
							id='email'
							name='email'
							type='email'
							onChange={formik.handleChange}
							value={formik.values.email}
							placeholder={'Add your email adress'}
						/>
						<Input
							id='password'
							name='password'
							type='password'
							onChange={formik.handleChange}
							value={formik.values.password}
							placeholder={'Add your password'}
						/>
						<Button type='submit'>Register</Button>
					</RegisterForm>
				</RegisterContainer>
				<FormFooter>
					<Paragraph>App not authorized by:</Paragraph>
					<FormLogos>
						<FormImg src={spursLogo} alt='Spurs Logo' padding={'20px'} />
						<FormImg src={nbaLogo} alt='NBA Logo' />
					</FormLogos>
				</FormFooter>
			</DivSquare>
		</ThemeProvider>
	)
}

export default RegistrationPage
