import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import Button2 from '../atoms/Button2.js'
import Input from '../atoms/input.js'
import InputWithState from '../molecules/inputwithstate.js'
import { auth , database } from '../../firebase/firebase'
import { updateProfile } from 'firebase/auth'
import { ref, set } from 'firebase/database';
import { showModal } from '../../actions/index.js'
import { useDispatch } from 'react-redux'

const NewNameInput = () => {
    const inputRef = useRef();
    const dispatch = useDispatch();

    const handleNameChange = e => {
        e.preventDefault();
        const inputValue = inputRef.current.value; // Poprawne użycie .value
        const user = auth.currentUser;
        if (user) {
            updateProfile(user, {
                displayName: inputValue,
            }).then(() => {
                // aktualizuje imię użytkownika w Realtime Database
                const userRef = ref(database, 'names/' + user.uid);
                set(userRef, { name: inputValue })
                .then(() => {
                    dispatch(showModal('Your name has been changed.'));

                    setTimeout(() => {
                        window.location.reload(); 
                    }, 1500)


                    
                }).catch(error => {
                    dispatch(showModal("Database error: " + error.message));
                });
            }).catch(error => {
                dispatch(showModal("Authization error: " + error.message));
            });
        }
    };

	const FormName = styled.form`
	display: flex;
	flex-direction: column;
    align-items: left;
	`

return (
	<FormName onSubmit={handleNameChange}>
		<InputWithState ref={inputRef} type='text' placeholder='Add new name...' />
		<Button2 type='submit' width='210px' margin='0px 0px 0px 0px' backgroundcolor='black' color='white'>
			Change your name
		</Button2>
	</FormName>
);
}

export default NewNameInput
