import React, { useState, forwardRef } from 'react';
import Input from '../atoms/input.js';

const InputWithState = forwardRef((props, ref) => {
    const { type, placeholder } = props;
    const [newStateInput, setStateInput] = useState('');

    const handleInputChange = e => {
        setStateInput(e.target.value);
    };

    return (
        <Input
            ref={ref}
            type={type}
            value={newStateInput}
            onChange={handleInputChange}
            placeholder={placeholder}
            margin='10px 0px 10px 0px'
        />
    );
});

export default InputWithState;
