import styled from 'styled-components'

const Paragraph = styled.header`
	font-style: normal;
	font-weight: ${({ theme }) => theme.light};
	font-size: ${({ fontsize }) => fontsize || '16px'};
	line-height: 18px;
	color: ${({ theme }) => theme.black};
	margin-bottom: ${({ marginbottom }) => marginbottom || '0px'};
	margin-right: ${({ marginright }) => marginright || '0px'};
`

export default Paragraph
