import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-modal'
import styled from 'styled-components'
import Paragraph from '../atoms/paragraph'
import appLogo from '../../assets/graphs/sochan-120.webp'

import { hideModal } from '../../actions'

const StyledModalContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	top: 50%;
	padding: 50px 50px 50px 50px;
	z-index: 1000;
	background-color: ${props => props.theme.black};
	color: ${({ theme }) => theme.white};
	border-radius: 25px;
	transition: opacity 0.5s, transform 0.5s;
	opacity: 0; /* Domyślnie ukryty */
	transform: translateY(-20px);
	font-size: 16px;
	text-align: center;

 

  @keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	&.is-open {
    animation: fadeIn .3s forwards;
		opacity: 1;
		transform: translate(0%, 50%);
	}

  

	@media (min-width: 768px) {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		top: 50%;
		left: 50%;
		padding: 150px;
		z-index: 1000;
		width: 50%;
		background-color: ${props => props.theme.black};
		color: ${({ theme }) => theme.white};
		border-radius: 22px;
		transition: opacity 0.5s, transform 0.5s;
		font-size: 25px;
		opacity: 0; /* Domyślnie ukryty */
		transform: translateY(-20px);
		&.is-open {
			opacity: 1;
			transform: translate(50%, 25%);
		}
	}
`

const LogoType = styled.img`
	width: 80px;
	margin: 0 0 30px 0;
	@media (min-width: 768px) {
		width: 120px;
	}
`

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 'none',
		backgroundColor: 'white',
		zIndex: '1000',
		width: '100vw',
		height: '100vh',
	},
}

const ModalComponent = () => {
	const { isOpen, content } = useSelector(state => state.items.modal)
	const dispatch = useDispatch()

	const closeModal = () => {
		dispatch(hideModal())
	}

	useEffect(() => {
		let timeoutId
		if (isOpen) {
			// Ustawienie timeoutu na 2 sekundy po otwarciu modala
			timeoutId = setTimeout(() => {
				dispatch(hideModal())
			}, 1500)
		}

		// Funkcja czyszcząca timeout, jeśli komponent zostanie odmontowany
		return () => clearTimeout(timeoutId)
	}, [isOpen, dispatch])

	return (
		<Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Modal'>
			<StyledModalContent className={isOpen ? 'is-open' : ''}>
				<LogoType src={appLogo} alt='App Logo'/>
				<Paragraph style={{ color: 'white' }}>{content}</Paragraph>
			</StyledModalContent>
		</Modal>
	)
}

export default ModalComponent
