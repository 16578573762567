import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../theme/GlobalStyle.js'
import { theme } from '../../theme/mainTheme.js'
import Sidebar from '../organisms/Sidebar.js'
import SmallHeader from '../atoms/SmallHeader.js'
import SearchInput from '../atoms/searchInput.js'
import Paragraph from '../atoms/paragraph.js'
import { AppContext } from '../../contexts/AppContext.js'
import ButtonIcon from '../atoms/Buttonicon.js'
import plus from '../../assets/icons/plus.svg'
import NewItemBar from '../organisms/NewItemBar.js'
import Card from '../molecules/Card.js'
import Button1 from '../atoms/Button1.js'
import { Helmet } from 'react-helmet';

const UserTemplate = ({ notesToMap }) => {
	//STANY DO WYSZUKIWARKI I SORTOWANIA

	const [searchTerm, setSearchTerm] = useState('')
	const [filteredItems, setFilteredItems] = useState([])
	const [sortType, setSortType] = useState('latest') // title-asc title-desc

	const location = useLocation()
	

	//KONTEKSTY

	const { appState, setCurrentPage, newItemBarState, setNewItemBarState, HandleNewItemBarState } =
		useContext(AppContext)
		
	setCurrentPage(location.pathname)

	// OBSŁUGA CALLBACK DO PRZEKAZYWANIA STANU INPUTA

	const handleSearchChange = newSearchTerm => {
		setSearchTerm(newSearchTerm)
		// Możesz tutaj również dodać logikę filtrowania elementów na podstawie `newSearchTerm` - ale dodałeś ją do useEffect żeby bazowac na stanie komponentu
	}

	// OBSŁUGA STANU DO SORTOWANIA

	const handleChangeSort = e => {
		setSortType(e.target.id)
	}

	//OBSŁUGA ZMIANY STANU WYSZUKIWANIA

	useEffect(() => {

		if (notesToMap) {
		
		let items = [...notesToMap]
		if (searchTerm.trim() === '') {
			setFilteredItems(items) // Jeśli fraza jest pusta, pokazuj wszystkie elementy
		} else {
			// Filtruj elementy na podstawie searchTerm
			items = items.filter(
				item =>
					item.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.title.toLowerCase().includes(searchTerm.toLowerCase())
			)
		}

		// Sortuj elementy na podstawie sortType
		switch (sortType) {
			case 'latest':
				items.sort((a, b) => parseInt(b.created) - parseInt(a.created))
				break
			case 'eldest':
				items.sort((a, b) => parseInt(a.created) - parseInt(b.created))
				break
			case 'title-asc':
				items.sort((a, b) => a.title.localeCompare(b.title))
				break
			case 'title-desc':
				items.sort((a, b) => b.title.localeCompare(a.title))
				break
			default:
				// Nie zmieniaj kolejności, jeśli sortType nie jest rozpoznany
				break
		}

		setFilteredItems(items) // Ustawienie przefiltrowanych elementów
	}}, [searchTerm, notesToMap, sortType])

	//STYLED COMPONENTS

	const StyledButton = styled(ButtonIcon)`
		position: fixed;
		border: 4px solid black;
		right: 40px;
		bottom: 40px;
		background-color: ${({ theme, inactiveColor }) => theme[inactiveColor]};
		/* background-color: ${props => props.theme[props.inactiveColor]}; bez destrukturyzacji */
		z-index: 30;
	`

	const MainContent = styled.div`
		position: relative;
		padding: 20px;
		margin-left: 90px;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 30px;

		@media (min-width: 768px) {
			margin-left: 157px;
		}

		@media (min-width: 992px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (min-width: 1200px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (min-width: 1400px) {
			grid-template-columns: repeat(4, 1fr);
		}
	`

	const StyledPageHeader = styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		margin-left: 90px;
		margin-right: 10px;
		padding-left: 20px;
		padding-top: 20px;

		@media (min-width: 768px) {
			margin-left: 157px;
		}
		
	`

	const StyledHeaderDivider = styled.div`
	display: flex;
		flex-direction: column;
		@media (min-width: 1200px) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	`


	const StyledTools = styled.div`
		display: flex;
		flex-direction: row;
		align-content: center;
		margin-bottom: 15px;
	`


	const StyledToolsContainer = styled.div`
		display: flex;
		flex-direction: column;
		@media (min-width: 650px) {
			flex-direction: row;
		}
	`
	//FUNKCJA STEROWANIA STANEM INPUTA WYSZUKIWARKI

	return (
		<div>
			<GlobalStyle />
			<ThemeProvider theme={theme}>
			<Helmet>
          <title>The best {appState} about the San Antonio Spurs and Jeremy Sochan</title>
		  <meta name="description" content={`Find out what the ${appState} are regarding the San Antonio Spurs and Jeremy Sochan. Click and enter the world of basketball.`} />
          <meta name="keywords" content={`${appState}, San Antonio, Górnik Wałbrzych, Basketball, Jeremy Sochan`} />
        </Helmet>
				<Sidebar pageType={appState} />
				<StyledPageHeader>

					<StyledHeaderDivider>
					<StyledButton icon={plus} inactiveColor={appState} onClick={HandleNewItemBarState} />
					<SearchInput placeholder='Enter search term...' onSearch={handleSearchChange} value={searchTerm}></SearchInput>

					<StyledToolsContainer>
						<Paragraph fontsize='12px' marginbottom='10px' marginright='10px'>
							sort by:
						</Paragraph>
						<StyledTools>
							<Button1 tools isActive={sortType === 'latest'} id='latest' onClick={handleChangeSort}>
								latest
							</Button1>
							<Button1 tools isActive={sortType === 'eldest'} id='eldest' onClick={handleChangeSort}>
								eldest
							</Button1>
						</StyledTools>
						<StyledTools>
							<Button1 tools id='title-asc' isActive={sortType === 'title-asc'} onClick={handleChangeSort}>
								title a-z
							</Button1>
							<Button1 tools id='title-desc' isActive={sortType === 'title-desc'} onClick={handleChangeSort}>
								title z-a
							</Button1>
						</StyledTools>
							
					</StyledToolsContainer>
					</StyledHeaderDivider>
					<SmallHeader size='24px'>Your {appState}:</SmallHeader>
				</StyledPageHeader>
				
				<MainContent>
					{filteredItems &&
						filteredItems.map(item  => (
							<Card
								cardType={appState}
								title={item.title}
								created={item.created}
								content={item.content}
								id={item.id}
								key={item.id}
								authorId={item.authorId}
							 />
						))}
				</MainContent>
				<NewItemBar color={appState} />
			</ThemeProvider>
		</div>
	)
}

UserTemplate.propTypes = {
	children: PropTypes.node.isRequired,
	pageType: PropTypes.string,
}

export default UserTemplate
