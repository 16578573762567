import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paragraph from '../atoms/paragraph.js'
import SmallHeader from '../atoms/SmallHeader.js'
import Button from '../atoms/Button.js'
import AddInfoSmall from '../atoms/AddInfoSmall.js'
import LinkIcon from '../../assets/icons/link.svg'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { removeItemThunk, addItemThunk } from '../../actions/dataThunks.js'
import { app } from '../../firebase/firebase.js'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { getDatabase, ref as dbRef, get } from 'firebase/database'
import { auth } from '../../firebase/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { getAuth } from 'firebase/auth'

const StyledWrapper = styled.div`
	box-shadow: 0 10px 30px -5px hsla(0, 0%, 0%, 0.1);
	border-radius: 10px;
	overflow: hidden;
	min-height: 300px;
	display: grid;
	grid-template-rows: 0.25fr 1fr;
	cursor: pointer;
	z-index: 0;
`

const InnerWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${({ activeColor, theme }) => (activeColor ? theme[activeColor] : 'white')};
	padding: 17px 30px 17px 30px;
`

const StyledLink = styled.a`
	display: block;
	width: 47px;
	height: 47px;
	border-radius: 50px;
	background: white url(${LinkIcon});
	background-size: contain;
	position: absolute;
	right: 25px;
	top: 20%;
`

const SmallHeaderContainer = styled.div`
	padding-right: 30px;
`

const StyledAvatar = styled.img`
	width: 75px;
	height: 75px;
	border-radius: 40px;
	border: 5px solid ${({ activeColor, theme }) => theme[activeColor]};
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 10;
`

const Card = ({
	cardType,
	title,
	created,
	twitterName,
	articleUrl,
	content,
	key,
	id,
	authorId,
	removeItemFunction,
}) => {
	const dispatch = useDispatch()
	const [handleClick, setHandleClick] = useState(false)
	const [cardAuthorPhoto, setCardAuthorPhoto] = useState('')
	const [cardAuthorName, setCardAuthorName] = useState('')
	const [cardUser, setCardUser] = useState(null)
	const [userId, setUserId] = useState(null)

	const handleCardClick = () => {
		setHandleClick(true)
	}

	const navigate = useNavigate()

	console.log(`to jest ${cardType}`)

	//Funkcja skracjąca teksty pobranego tytułu i contentu

	if (handleClick && navigate(`/${cardType}/${id}`));

	const itemDate = new Date(created)
	const formattedDate = itemDate.toLocaleDateString('pl-PL')

	const cutTextAtWordBoundary = (text, maxLength) => {
		if (text.length <= maxLength) {
			return text
		}

		const subString = text.substr(0, maxLength)
		const lastSpaceIndex = subString.lastIndexOf(' ')

		if (lastSpaceIndex === -1) {
			// Nie ma spacji, zwracamy cały ciąg
			return subString
		} else {
			// Zwracamy tekst do ostatniej spacji
			return subString.substr(0, lastSpaceIndex) + '...'
		}
	}

	const newTitle = cutTextAtWordBoundary(title, 35)
	const newContent = cutTextAtWordBoundary(content, 160)

	//Pobieranie imienia i foty autora

	useEffect(() => {
		if (authorId) {
			// Pobieranie zdjęcia autora
			const storage = getStorage(app)
			const userPhotoRef = ref(storage, `profilePictures/${authorId}`)
			getDownloadURL(userPhotoRef)
				.then(url => {
					setCardAuthorPhoto(url)
				})
				.catch(error => {
					// Ustawienie domyślnego zdjęcia, jeśli nie uda się pobrać
					setCardAuthorPhoto(
						'https://firebasestorage.googleapis.com/v0/b/favnote-a09d2.appspot.com/o/profilePictures%2FR4498siYdXSYWt9rUk8oRGdvTso1?alt=media&token=b5021fbf-58d1-439b-ac6d-ff535bde71ed'
					)
				})

			// Nowy kod do pobierania imienia autora z Realtime Database
			const database = getDatabase(app)
			const nameRef = dbRef(database, `names/${authorId}`)
			get(nameRef)
				.then(snapshot => {
					if (snapshot.exists()) {
						setCardAuthorName(snapshot.val().name) // Ustawienie imienia autora
					} else {
						console.log('No data available for author name.')
					}
				})
				.catch(error => {
					console.error(error)
				})
		}
	}, [])

	//Pobieranie imienia zalogowanego

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, currentUser => {
			setCardUser(currentUser)
		})

		// Funkcja czyszcząca na wypadek, gdy komponent będzie odmontowywany
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		const auth = getAuth()
		const user = auth.currentUser
		if (user) {
			setUserId(user.uid)
		}
	}, [])

	return (
		<StyledWrapper onClick={handleCardClick}>
			<InnerWrapper activeColor={cardType}>
				<SmallHeaderContainer>
					<SmallHeader>{newTitle}</SmallHeader>
				</SmallHeaderContainer>
				<AddInfoSmall>
					Created: {formattedDate} by {cardAuthorName}
				</AddInfoSmall>

				<StyledAvatar src={cardAuthorPhoto} activeColor={cardType} />
			</InnerWrapper>
			<InnerWrapper>
				<Paragraph>{newContent}</Paragraph>

				{cardUser && authorId === cardUser.uid ? (
					<Button
						onClick={event => {
							event.stopPropagation()
							dispatch(removeItemThunk(cardType, id))
						}}>
						remove
					</Button>
				) : !userId ? (
					<Button onClick={() => navigate('/login')}>ask to remove</Button>
				) : (
					<Button
						onClick={event => {
							event.stopPropagation()
							const articleId = id
							dispatch(addItemThunk(`asktoremove`, { authorId, userId, cardType, articleId }))
						}}>
						ask to remove
					</Button>
				)}
			</InnerWrapper>
		</StyledWrapper>
	)
}

Card.propTypes = {
	cardType: PropTypes.oneOf(['note', 'twitter', 'article']),
	title: PropTypes.string.isRequired,
	created: PropTypes.string.isRequired,
	twitterName: PropTypes.string,
	articleUrl: PropTypes.string,
	content: PropTypes.string.isRequired,
	removeItem: PropTypes.func.isRequired,
}

Card.defaultProps = {
	cardType: 'note',
	twitterName: null,
	articleUrl: null,
}

// const mapDispatchToProps = dispatch => ({
// 	removeItemFunction: (itemType, itemContent) => dispatch(removeItem(itemType, itemContent))

// })

export default Card
