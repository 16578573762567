import React, { useContext } from 'react'
import DetailsTemplate from '../components/templates/DetailsTemplate'
import Modal from 'react-modal';

const customStyles = {
	content: {
	  top: '50%',
	  left: '50%',
	  right: 'auto',
	  bottom: 'auto',
	  marginRight: '-50%',
	  transform: 'translate(-50%, -50%)',
	},
  };

const DetailsPage = ({ pageType }) => {
	
	const [modalIsOpen, setIsOpen] = React.useState(false);

	function openModal() {
	  setIsOpen(true);
	}
  
	function closeModal() {
	  setIsOpen(false);
	}


	return (
		<>
		<DetailsTemplate pageType={pageType} />
		</>
	)
}

export default DetailsPage
