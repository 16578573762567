// Akcje do otwierania modala
export const showModal = content => ({
	type: 'SHOW_MODAL',
	payload: content,
})

// Akcje do zamykania modala
export const hideModal = () => ({
	type: 'HIDE_MODAL',
})

// Akcja sukcesu pobierania danych
export const fetchDataSuccess = (data, itemType) => {
	return {
		type: 'FETCH_DATA_SUCCESS',
		payload: data,
		itemType,
	}
}

// Akcja błędu pobierania danych
export const fetchDataFailure = error => {
	return {
		type: 'FETCH_DATA_FAILURE',
		payload: error,
	}
}

// Akcja włączania loading
export const fetchStartLoading = () => {
	return {
		type: 'FETCH_DATA_START',
	}
}

// Akcja wyłączania loading
export const fetchEndLoading = () => {
	return {
		type: 'FETCH_DATA_END',
	}
}

//Akcje do zgłaszania usunuęcartykułu

export const askToRemoveRequest = () => ({
	type: 'ASK_TO_REMOVE_REQUEST',
})

export const askToRemoveSuccess = data => ({
	type: 'ASK_TO_REMOVE_SUCCESS',
	payload: data,
})

export const askToRemoveFailure = error => ({
	type: 'ASK_TO_REMOVE_FAILURE',
	payload: error,
})

// Akcja nie używana, thunk usuwa wpis z firebase + nasłuch firebase i automat eksportujący do store.

export const removeItem = (itemType, id) => {
	return {
		type: 'REMOVE_ITEM',
		payload: {
			itemType,
			id,
		},
	}
}

// Akcja nie używana, thunk ładuje do firebase + nasłuch firebase i automat eksportujący do store.

export const addItem = (itemType, itemContent) => {
	const getId = () => `_${Math.random().toString(36).substr(2, 9)}`

	const itemDate = new Date().getTime()
	return {
		type: 'ADD_ITEM',
		payload: {
			itemType,
			item: {
				id: getId(),
				created: itemDate,
				...itemContent,
			},
		},
	}
}
