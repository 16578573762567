import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyCyadLyfG484N4DgkVPibjezioJcuKNvXQ",
    authDomain: "favnote-a09d2.firebaseapp.com",
    databaseURL: "https://favnote-a09d2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "favnote-a09d2",
    storageBucket: "favnote-a09d2.appspot.com",
    messagingSenderId: "65382854309",
    appId: "1:65382854309:web:71fb578e106f8932a6e74c",
};

// Inicjalizacja aplikacji Firebase
const app = initializeApp(firebaseConfig);

// Inicjalizacja usługi uwierzytelniania
const auth = getAuth(app);

// Inicjalizacja bazy danych
const database = getDatabase(app);

// Inicjalizacja Firebase Storage
const storage = getStorage(app);

export { auth, app, database, storage };