import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';

const YTWrapper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

const YouTubeScreen = ({ data }) => {
  const [opts, setOpts] = useState({});
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    const updateSize = () => {
      let newOpts;
      if (window.matchMedia('(min-width: 1200px)').matches) {
        newOpts = { height: '590', width: '1050' };
      } else if (window.matchMedia('(min-width: 992px)').matches) {
        newOpts = { height: '450', width: '800' };
      } else if (window.matchMedia('(min-width: 576px)').matches) {
        newOpts = { height: '253', width: '450' };
      } else {
        newOpts = { height: '112', width: '200' };
      }
      setOpts(newOpts);
      setResizeKey(prevKey => prevKey + 1);
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <YTWrapper width={opts.width} height={opts.height}>
      <YouTube key={resizeKey} videoId={data} opts={opts} />
    </YTWrapper>
  );
};

export default YouTubeScreen;
