import { createStore, applyMiddleware, compose  } from 'redux'
import {thunk} from 'redux-thunk'
import personReducer from '../reducers/index.js'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    items: personReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
    rootReducer,
     composeEnhancers(applyMiddleware(thunk))
   );

export default store;