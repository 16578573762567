export const theme = {
notes: 'hsl(30.59, 100%, 50%)',
twitters: 'hsl(176.97,100%,34.9%)',
articles: 'hsl(204, 2.18%, 55.1%)',
videos: 'hsl(344.39, 84.39%, 59.8%)',
grey100: 'hsl(0,0%,96%)',
grey200: 'hsl(0,0%,90%)',
black: 'hsl(0,0%,0%)',
white: 'hsl(0, 0%, 100%)',
light: 300,
bold: 600,
}