import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase/firebase'
import { Link } from 'react-router-dom'
import ButtonIcon from '../atoms/Buttonicon.js'
import olowek from '../../assets/icons/olowek.svg'
import login from '../../assets/icons/login.svg'
import twitter from '../../assets/icons/twitterwhite.svg'
import zarowa from '../../assets/icons/press.svg'
import youtube from '../../assets/icons/youtube1.svg'
import SidebarContainer from './SidebarContainer.js'
import sochanLogo from '../../assets/graphs/sochan-120.webp'
import { onAuthStateChanged } from 'firebase/auth'
import { SiedbarLinks, SiedbarLogout, SidebarUserInfo } from './SidebarContainer.js'
import LogoutButton from '../molecules/logOutIcon.js'
import StyledAvatar from '../atoms/StyledAvatar.js'
import LoggedParagraph from '../atoms/LoggedParagraph.js'
import styled from 'styled-components'
import { showModal } from '../../actions/index.js'

const Sidebar = ({ pageType }) => {
	const Logo = styled.div`
		img {
		
			max-width: 70px;
			height: auto;

			
		}
	`
	const [user, setUser] = useState(null)

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, currentUser => {
			setUser(currentUser)
		})

		// Funkcja czyszcząca na wypadek, gdy komponent będzie odmontowywany
		return () => unsubscribe()
	}, [])

	return (
		<SidebarContainer pageType={pageType}>
			<Logo>
				<img src={sochanLogo} alt='Sochan News' />
			</Logo>

			<SiedbarLinks>
				<ButtonIcon
					id='1'
					as={Link}
					to='/notes'
					icon={olowek}
					active={pageType === 'notes' ? 'true' : 'false'}
					pageType={pageType}
				/>

				<ButtonIcon
					id='2'
					as={Link}
					to='/twitters'
					icon={twitter}
					active={pageType === 'twitters' ? 'true' : 'false'}
					pageType={pageType}
				/>

				<ButtonIcon
					id='3'
					as={Link}
					to='/articles'
					icon={zarowa}
					active={pageType === 'articles' ? 'true' : 'false'}
					pageType={pageType}
				/>

				<ButtonIcon
					id='4'
					as={Link}
					to='/videos'
					icon={youtube}
					active={pageType === 'videos' ? 'true' : 'false'}
					pageType={pageType}
				/>
			</SiedbarLinks>

			<SiedbarLogout>
				{auth.currentUser ? <LogoutButton /> : <ButtonIcon as={Link} to='/login' icon={login} />}
			</SiedbarLogout>
			<SidebarUserInfo>
				{user && <LoggedParagraph fontsize='10px'>Logged in as:</LoggedParagraph>}
				{user && (
					<LoggedParagraph fontweight='600' fontsize='12px'>
						{user.displayName}
					</LoggedParagraph>
				)}
				{user && <StyledAvatar src={user.photoURL} alt='Avatar' to='/settings' rotateOnHover = {true}/>}
			</SidebarUserInfo>
		</SidebarContainer>
	)
}

export default Sidebar
