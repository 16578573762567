import React from 'react'
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import olowek from '../../assets/icons/olowek.svg'
import exit from '../../assets/icons/exit.svg'
import plus from '../../assets/icons/plus.svg'
import twitter from '../../assets/icons/twitter.svg'
import zarowa from '../../assets/icons/zarowa.svg'
import { AppContext } from '../../contexts/AppContext.js'

const ButtonIcon = styled.button`
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 20px;
	background-image: url(${({ icon }) => icon});
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 40%;
	border: none;
	background-color: ${({ active, theme, pageType }) => (active === 'true' ? theme[pageType] : 'transparent')};
	transition: all 0.3s ease-out;
	cursor: pointer;

	/* ${({ twitters }) =>
		twitters &&
		css`
			background-color: ${({ theme }) => theme.twitters};
		`};

	${({ articles }) =>
		articles &&
		css`
			background-color: ${({ theme }) => theme.articles};
		`};

	${({ notes }) =>
		notes &&
		css`
			background-color: ${({ theme }) => theme.notes};
		`}; */
`

export default ButtonIcon
