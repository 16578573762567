import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserTemplate from '../components/templates/UserTemplate.js'
import Card from '../components/molecules/Card.js'
import { fetchData } from '../actions/dataThunks.js'

const Notes = () => {
	const dispatch = useDispatch()
	const { notes, loading, error } = useSelector(state => state.items)

	useEffect(() => {
		dispatch(fetchData('notes'))
	}, [dispatch])

	if (loading) return <p>Loading...</p>
	if (error) return <p>Error: {error}</p>

	return (
		<UserTemplate notesToMap = {notes}/>
	)
}

export default Notes
