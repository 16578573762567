import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { removeItemThunk, ignoreRequestThunk } from '../../actions/dataThunks.js'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../actions/dataThunks.js'
import Button2 from '../atoms/Button2.js'

const List = styled.div`
display: flex;
flex-direction: column;

	margin: 0px 0px 30px 0px;
	padding: 20px 0px 0px 0px;
	border-top: 2px solid black;
	min-width: 225px;
	width: 100%;

	@media (min-width: 768px) {
		border: none;
		padding: 0px 20px 0px 0px;
	}

	@media (min-width: 1200px) {
		border: none;
		padding: 0px 20px 0px 0px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`

const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
margin: 10px 0px 0px 0px;
@media (min-width: 768px) {
		flex-direction: row;
		margin: 0px 0px 0px 20px;
	}

`

const Div = styled.div``

const LineToRemove = ({ cardType, authorId, askerId, itemId, articleId }) => {
	const dispatch = useDispatch()

	const items = useSelector(state => state.items)

	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const dataTypes = ['twitters', 'notes', 'articles', 'videos', 'asktoremove']
		Promise.all(dataTypes.map(type => dispatch(fetchData(type))))
			.then(() => setIsLoading(false)) // Ustawiamy isLoading na false po załadowaniu wszystkich danych
			.catch(error => console.error('Error loading data: ', error))
	}, [dispatch])

	const showTitle = (itemId, cardType) => {
		const data = items[cardType]

		if (itemId && data && data.length > 0) {
			const noteToTitle = data.find(note => note.id === articleId)

			return noteToTitle ? noteToTitle.title : 'Title not found'
		}
	}

	if (isLoading) {
		return <div>Loading data...</div> // Wyświetl komunikat o ładowaniu, gdy dane są jeszcze ładowane
	}

	return (
		<List>
			<Div>
				Request to remove {cardType.slice(0, -1)}: {showTitle(itemId, cardType)}
			</Div>
			<ButtonContainer>

			<Button2
				backgroundcolor='black'
				color='white'
				margin={'0px 0px 0px 0px'}
				width={'200px'}
				onClick={event => {
					event.stopPropagation()
					dispatch(removeItemThunk(cardType, articleId))
				}}>
				remove item
			</Button2>

			<Button2 margin={'0px 0px 0px 10px'} width={'200px'} onClick={event => {
					event.stopPropagation()
					dispatch(ignoreRequestThunk(cardType, articleId))
				}}>
				inore request
			</Button2></ButtonContainer>
		</List>
	)
}

export default LineToRemove
