export const routes = {
	home: '/',
	login: '/login',
	register: '/register',
	success: '/success',
	failure: '/failure',
	notes: '/notes',
	articles: '/articles',
	twitters: '/twitters',
	videos: '/videos',
	note: '/notes/:id',
	article: '/articles/:id',
	twitter: '/twitters/:id',
	video: '/videos/:id',
	settings: '/settings',
};
