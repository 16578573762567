import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../store/index.js'
import Notes from './Notes.js'
import Articles from './Articles.js'
import Twitters from './Twitters.js'
import Videos from './Videos.js'
import DetailsPage from './DetailsPage.js'
import Login from './Login.js'
import RegistrationPage from './Register.js'
import SuccessPage from './Success.js'
import FailurePage from './Failure.js'
import UpdateProfile from './Settings.js'
import DataFetcher from './dataFetcher.js'
import { routes } from '../routes'
import { AppProvider } from '../contexts/AppContext'
import ProtectedRoute from './ProtectedRoute.js'

const Root = () => {
	return (
		<Provider store={store}>
			<AppProvider>
				<DataFetcher>
					<BrowserRouter>
						<Routes>
							<Route exact path={routes.home} element={<Navigate to='/notes' replace />} />

							<Route exact path={routes.notes} element={<Notes />} />

							<Route path={routes.note} element={<ProtectedRoute><DetailsPage pageType='notes' /></ProtectedRoute>} />

							<Route
								exact
								path={routes.articles}
								element={
									// <ProtectedRoute>
									<Articles />
									// </ProtectedRoute>
								}
							/>

							<Route
								path={routes.article}
								element={
									<ProtectedRoute>
										<DetailsPage pageType='articles' />
									</ProtectedRoute>
								}
							/>

							<Route exact path={routes.twitters} element={<Twitters />} />

							<Route
								path={routes.twitter}
								element={
									<ProtectedRoute>
										<DetailsPage pageType='twitters' />
									</ProtectedRoute>
								}
							/>

							<Route exact path={routes.videos} element={<Videos />} />

							<Route
								path={routes.video}
								element={
									<ProtectedRoute>
										<DetailsPage pageType='videos' />
									</ProtectedRoute>
								}
							/>

							<Route exact path={routes.login} element={<Login />} />
							<Route exact path={routes.register} element={<RegistrationPage />} />
							<Route exact path='/success' element={<SuccessPage />} />
							<Route exact path='/failure' element={<FailurePage />} />
							<Route exact path='/settings' element={<UpdateProfile />} />
						</Routes>
					</BrowserRouter>
				</DataFetcher>
			</AppProvider>
		</Provider>
	)
}

export default Root
