import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData } from '../actions/dataThunks'

const DataFetcher = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData()); // Pobierz dane przy starcie aplikacji
  }, [dispatch]);

  return <>{children}</>;
};

export default DataFetcher;