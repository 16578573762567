import { database } from '../firebase/firebase.js'
import { ref, onValue, push, remove, get } from 'firebase/database'
import {
	fetchDataSuccess,
	fetchDataFailure,
	fetchStartLoading,
	fetchEndLoading,
	askToRemoveRequest,
	askToRemoveSuccess,
	askToRemoveFailure,
	showModal
} from './index.js' // importujesz akcje


export const fetchData = itemType => {
	return dispatch => {
		if (!itemType) {
			console.error('fetchData wywołane bez itemType!')
			return
		}

		const dataRef = ref(database, itemType)

		onValue(
			dataRef,
			snapshot => {
				const data = snapshot.val()
				if (data) {
					const dataArray = Object.keys(data).map(key => ({
						...data[key],
						id: key,
					}))
					
					dispatch(fetchDataSuccess(dataArray, itemType))
				} else {
					console.log('Brak danych w Firebase.')
					dispatch(fetchDataFailure('No data available'))
				}
			},
			{}
		)
	}
}

export const addItemThunk = (itemType, itemContent, authorId) => {
	
	return (dispatch, getState) => {
		dispatch(fetchStartLoading())
		const newItemRef = ref(database, itemType)

		const contentWithUser = authorId ? { ...itemContent, authorId } : itemContent

		push(newItemRef, contentWithUser)
			.then(() => {
				dispatch(fetchEndLoading())
				dispatch(fetchDataSuccess('Element został pomyślnie dodany.'))

				let alertMessage;
				switch (itemType) {
					case 'notes':
						alertMessage = 'The note has been added.';
						break;
					case 'twitters':
						alertMessage = 'The tweet has been added.';
						break;
					case 'articles':
						alertMessage = 'The article has been added.';
						break;
					case 'videos':
						alertMessage = 'The video has been added.';
						break;
					case 'asktoremove':
						alertMessage = 'Your request has been sent to the author.';
						break;
					default:
						alertMessage = 'Idont know what but something is wrong.';
				}
				dispatch(showModal(alertMessage));
			})
			.catch(error => {
				dispatch(fetchDataFailure(error.message))
				dispatch(fetchEndLoading())
			})
	}
}

export const removeItemThunk = (itemType, itemID) => {
    return (dispatch, getState) => {
        dispatch(fetchStartLoading());
        const itemToRemoveRef = ref(database, `${itemType}/${itemID}`);

        // Najpierw usuń artykuł
        remove(itemToRemoveRef)
            .then(() => {
                // Artykuł został usunięty, teraz znajdź i usuń wszystkie związane prośby
                const askToRemoveRef = ref(database, `asktoremove`);
                get(askToRemoveRef).then((snapshot) => {
                    if (snapshot.exists()) {
                        const requests = snapshot.val();
                        // Przefiltruj, aby znaleźć te, które należy usunąć
                        const requestsToRemove = Object.keys(requests).filter(
                            key => requests[key].articleId === itemID
                        );

                        // Usuń każdą z nich
                        const removePromises = requestsToRemove.map(requestID => {
                            return remove(ref(database, `asktoremove/${requestID}`));
                        });

                        return Promise.all(removePromises);
                    }
                }).then(() => {
                    dispatch(fetchEndLoading());
                    dispatch(showModal("Your item is removed."));
                }).catch(error => {
					dispatch(showModal("Error while removing the deletion request."));
                    dispatch(fetchEndLoading());
                });
            })
            .catch(error => {
				dispatch(showModal('Failed to delete the selected item.'));
                dispatch(fetchEndLoading());
            });
    };
};


export const ignoreRequestThunk = (itemType, itemID) => {
    return (dispatch, getState) => {
        dispatch(fetchStartLoading());
        
                const askToRemoveRef = ref(database, `asktoremove`);
                get(askToRemoveRef).then((snapshot) => {
                    if (snapshot.exists()) {
                        const requests = snapshot.val();
                        // Przefiltruj, aby znaleźć te, które należy usunąć
                        const requestsToRemove = Object.keys(requests).filter(
                            key => requests[key].articleId === itemID
                        );

                        // Usuń każdą z nich
                        const removePromises = requestsToRemove.map(requestID => {
                            return remove(ref(database, `asktoremove/${requestID}`));
                        });

                        return Promise.all(removePromises);
                    }
                }).then(() => {
                    dispatch(fetchEndLoading());
                    dispatch(showModal("Another user's request has been ignored."));
                }).catch(error => {
                    dispatch(fetchDataFailure('Error while ignoring the deletion request.'));
                    dispatch(fetchEndLoading());
                }).catch(error => {
					dispatch(showModal("Error while deleting the item."));
                dispatch(fetchEndLoading());
            });
    };
};



export const askToRemove = (authorId, cardUser, cardType, id) => {
	return dispatch => {
		dispatch(askToRemoveRequest())
		const askToRemoveItem = ref(database, `asktoremove`)
		const removeOrder = {
			authorId,
			cardUser,
			cardType,
			id,
		}
		push(askToRemoveItem, removeOrder)
		.then(() => dispatch(askToRemoveSuccess({ authorId, cardUser, cardType, id })))
		.catch(error => dispatch(askToRemoveFailure(error)))
	}
}