import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import magnifierIcon from '../../assets/icons/magnifier.svg'
import x from '../../assets/icons/x.svg'

const SearchWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const StyledInput = styled.input`
	padding: 15px 30px;
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: ${theme => theme.regular};
	background-color: ${({ theme }) => theme.grey100};
	border: none;
	border-radius: 50px;

	::placeholder {
		text-transform: uppercase;
		letter-spacing: 1px;
		color: ${({ theme }) => theme.grey200};
	}

	${({ search }) =>
		search &&
		css`
			font-size: 14px;
			padding: 10px 20px 10px 40px;
			
			background-size: 20px;
			background-position: 10px 50%;
			background-repeat: no-repeat;
           
		`}
`

const Icon = styled.img`
    position: absolute;
    left: 15px;
	top: 9px;
    cursor: pointer;
	color: "red";
`;

// Nowy komponent SearchInput
const SearchInput = ({ value, placeholder, onSearch }) => {
    const [inputValue, setInputValue] = useState(value);

    const handleChange = (e) => {
		const newValue = e.target.value;
        setInputValue(newValue);
		
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
			e.preventDefault();
			const newValue = e.target.value;
            onSearch(newValue)
			e.target.focus(); 
        }
    };

	const clearInput = () => {
        setInputValue('');
        onSearch('')
    };

    return (

		<SearchWrapper>
        <StyledInput
            type="text"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            search={true} // Włącza styl wyszukiwania
        />
		<Icon 
                src={inputValue ? x : magnifierIcon} 
                onClick={inputValue ? clearInput : null} // Tylko dodaje możliwość kliknięcia, gdy inputValue nie jest puste
            /></SearchWrapper>
    );
};

export default SearchInput;