import React, { createContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
export const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
	const [appState, setAppState] = useState('notes')
	const [appNumber, setAppNumber] = useState('')
	const [newItemBarState, setNewItemBarState] = useState(false)

	const setCurrentPage = locationFrom => {
		if (locationFrom.includes('notes')) {
			setAppState('notes')
		} else if (locationFrom.includes('twitters')) {
			setAppState('twitters')
		} else if (locationFrom.includes('articles')) {
			setAppState('articles')
		} else {
			setAppState('videos')
		}
	}

	const setCurrentPageNumber = loactionFrom => {
		const newNumber = loactionFrom.split('/').pop()
		setAppNumber(newNumber)
	}

	const HandleNewItemBarState = () => {
		setNewItemBarState(prevState => !prevState)
	}

	return (
		<AppContext.Provider
			value={{
				appState,
				setAppState,
				setCurrentPage,
				newItemBarState,
				setNewItemBarState,
				HandleNewItemBarState,
				appNumber,
				setCurrentPageNumber,
			}}>
			{children}
		</AppContext.Provider>
	)
}
