import React, { useState } from 'react'
import styled from 'styled-components'
import settings from '../../assets/icons/icons8-settings.svg'
import { Link } from 'react-router-dom'

const AwatarImg = styled.img`
		margin-top: 20px;
		width: ${(props) => props.w || '60px'};
		height: ${(props) => props.h || '60px'};
		border-radius: ${(props) => props.br || '30px'};
		right: 15px;
		z-index: 10;
		cursor: pointer;
		transition: transform 0.3s;

		&:hover {
        transform: ${(props) => (props.rotateOnHover ? 'rotate(30deg)' : 'none')};
    };
	`

const StyledAvatar = ({ src, alt, to, h, w, br, rotateOnHover = true }) => {
	const [hover, setHover] = useState(false)

	

	return (
		<Link to={to}>
			<AwatarImg
				src={(rotateOnHover && hover) ? settings : src}
				alt={alt}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onClick={() => {}}
				w={w} 
				h={h}
				br={br}
				rotateOnHover={rotateOnHover}
			/>
		</Link>
	)
}

export default StyledAvatar
