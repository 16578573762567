import styled from "styled-components";

const AddInfoSmall = styled.header`


font-weight: ${({theme}) => theme.bold};
padding-top: 12px;
font-size: 12px;
line-height: 12px;
color: ${({theme}) => theme.black};
font-family: "Montserrat", sans-serif;





`
export default AddInfoSmall;