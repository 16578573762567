import React, { useState, forwardRef } from 'react';
import Input from '../atoms/input.js';

const FileInputWithState = forwardRef(({ placeholder, onChange, ...props }, ref) => {
    // Stan dla przechowywania wybranego pliku
    const [file, setFile] = useState(null);

    const handleFileChange = e => {
        // Ustaw wybrany plik i opcjonalnie wywołaj przekazany handler onChange
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (onChange) {
            onChange(selectedFile);
        }
    };

    return (
        <Input
            ref={ref}
            type="file"
            onChange={handleFileChange}
            placeholder={placeholder}
            {...props} // Przekazanie dodatkowych propsów, np. margin
        />
    );
});

export default FileInputWithState;