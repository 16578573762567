import styled, { css } from 'styled-components'

export const Logo = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
`

export const LogoH1 = styled.p`
	font-size: 2.4rem;
	line-height: 0.9;
	margin: 0;
`
export const LogoH2 = styled.p`
	font-size: 1.5rem;
	line-height: 0.8;
	margin: 0;
`

export const SiedbarLinks = styled.div`
	display: flex;
	flex-direction: column;
	height: 300px;
	justify-content: space-around;
`
export const SiedbarLogout = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: top;

`

export const SidebarUserInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const SidebarContainer = styled.div`
	position: fixed;
	width: 90px;
	height: 100%;
	background-color: ${({ theme }) => theme.black};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	@media (min-width: 768px) {
		width: 157px;
	}

	${({ pageType }) =>
		pageType === 'notes' &&
		css`
			background-color: ${({ theme }) => theme.black};
		`};

	${({ pageType }) =>
		pageType === 'articles' &&
		css`
			background-color: ${({ theme }) => theme.black};
		`};

	${({ pageType }) =>
		pageType === 'twitters' &&
		css`
			background-color: ${({ theme }) => theme.black};
		`};
	${({ pageType }) =>
		pageType === 'settings' &&
		css`
			background-color: ${({ theme }) => theme.black};
		`};

	${({ pageType }) =>
		pageType === 'videos' &&
		css`
			background-color: ${({ theme }) => theme.black};
		`};
`

export default SidebarContainer
