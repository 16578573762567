import styled, { css } from 'styled-components'
import { theme } from '../../theme/mainTheme'

const Button2 = styled.button`
	padding: 0;
	background-color: ${theme.secondary};
	border: none;

	width: ${({ width }) => width || '200px'}; // po podanym propsie z destrukturyzacją, po propsie
	font-size: ${({ fs }) => fs || '14px'};
	height: 47px;
	margin: ${({ margin }) => margin || '0px'};
	background-color: ${({ backgroundcolor, theme }) => backgroundcolor || theme.secondary};
	color: ${({ color, theme }) => color || theme.secondary};
	cursor: pointer;

	${({ secondary }) =>
		secondary &&
		css`
			font-size: 10px;
			height: 30px;
		`}; // zebranie kilku parametrów w jedna funkcje strzalkową, props zdestrukturyzowany

	text-transform: uppercase;
	border-radius: 50px;
`

export default Button2
