const initialState = {
	twitters: [],
	notes: [],
	articles: [],
	videos: [],
	asktoremove: [],
	loading: false,
	error: null,
	modal: {
		isOpen: false,
		content: '',
	},
}

const rootReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'REMOVE_ITEM':
			return {
				...state,
				[action.payload.itemType]: state[action.payload.itemType].filter(item => item.id !== action.payload.id),
			}
		case 'ADD_ITEM':
			return {
				...state,
				[action.payload.itemType]: [...state[action.payload.itemType], action.payload.item],
			}
		case 'FETCH_DATA_SUCCESS':
			return {
				...state,
				[action.itemType]: action.payload,
				loading: false,
				error: null,
			}
		case 'FETCH_DATA_FAILURE':
			alert('Coś się zepsuło:', action.payload)
			return state // Pamiętaj, aby zwrócić aktualny stan w przypadku niezdefiniowanej akcji.
		case 'ASK_TO_REMOVE_REQUEST':
			return {
				...state,
				askToRemove: { ...state.askToRemove, loading: true, error: null },
			}
		case 'ASK_TO_REMOVE_SUCCESS':
			// Zakładając, że chcemy dodać dane do listy istniejących próśb
			return {
				...state,
				askToRemove: {
					...state.askToRemove,
					loading: false,
					data: [...state.askToRemove.data, action.payload],
					error: null,
				},
			}
		case 'ASK_TO_REMOVE_FAILURE':
			return {
				...state,
				askToRemove: { ...state.askToRemove, loading: false, error: action.payload },
			}
		case 'SHOW_MODAL':
			return {
				...state,
				modal: {
						// Aktualizujemy obiekt modala
					isOpen: true,
					content: action.payload,
				},
			}
		case 'HIDE_MODAL':
			return {
				...state,
				modal: {
					// Aktualizujemy obiekt modala
					isOpen: false,
					content: '',
				},
			}

		default:
			return state
	}
}

export default rootReducer
