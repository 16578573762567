import React, { useState } from 'react'
import styled from 'styled-components'
import { auth } from '../firebase/firebase'
import { useNavigate } from 'react-router-dom' // Import useNavigate hook
import { signInWithEmailAndPassword } from 'firebase/auth'
import spursLogo from '../assets/graphs/spurs-140.webp'
import nbaLogo from '../assets/graphs/nba-140.webp'
import appLogo from '../assets/graphs/sochan-120.webp'
import GlobalStyle from '../theme/GlobalStyle.js'
import { theme } from '../theme/mainTheme.js'
import { ThemeProvider } from 'styled-components'
import Input from '../components/atoms/input.js'
import Button from '../components/atoms/Button'
import Paragraph from '../components/atoms/paragraph.js'
import { Helmet } from 'react-helmet'
import ModalComponent from '../components/molecules/modal.js'
import { useDispatch } from 'react-redux'
import { showModal} from '../actions/index.js'

const DivSquare = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	
`

const FormFooter = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
`

const FormLogin = styled.form`
	display: flex;
	flex-direction: column;
	background-color: ${props => props.theme.black};
	border-radius: 25px;
	justify-content: center;
	align-items: center;
	padding: 30px 30px;
	margin-top: 30px;

	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
`

const FormLogos = styled.div`
	display: flex;
	justify-content: space-around;
`

const FormImg = styled.img`
	width: 60px;
	margin-top: 10px;
`

const FormForm = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px 0px;
	justify-content: center;
	align-items: center;
	z-index: 0;

	@media (min-width: 768px) {
		margin-left: 30px;
	}
`




const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	
	
	const navigate = useNavigate() // Hook do nawigacji

	const dispatch = useDispatch()

	const handleLogin = async e => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
          
            dispatch(showModal('You are logged in App!'));
           
            
                navigate('/notes');
           
        } catch {
            
            dispatch(showModal('Login issue. Please check the accuracy of your username or password.'));

      
        }
    };

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Helmet>
				<title>Please log in your account</title>
				<meta name='description' content={`Here you can log in 10 portal.`} />
				<meta
					name='keywords'
					content={`San Antonio, Górnik Wałbrzych, Basketball, Jeremy Sochan, nba, scores, video, twitters`}
				/>
			</Helmet>
			<DivSquare>
			

				<Paragraph>
					If you don't have an account, click <a href='/register'>here</a> to create one.
				</Paragraph>
				<FormLogin onSubmit={handleLogin}>
					<img src={appLogo} alt='Spurs Logo' />
					<FormForm>
						<Input type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' />
						<Input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder='Hasło' />
						<Button type='submit'>LOGIN</Button>
					</FormForm>
				</FormLogin>

				<FormFooter>
					<Paragraph>App not authorized by:</Paragraph>
					<FormLogos>
						<FormImg src={spursLogo} alt='Spurs Logo' padding={'20px'} />
						<FormImg src={nbaLogo} alt='NBA Logo' />
					</FormLogos>
				</FormFooter>
			</DivSquare>
		</ThemeProvider>
	)
}

export default Login
