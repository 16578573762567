import styled from "styled-components";

const SmallHeader = styled.header`

font-size: ${({size}) => size ? size : '20px'};
line-height: 24px;
font-weight: ${({theme}) => theme.light};
font-style: normal;

`

export default SmallHeader;