import React, { useState, useEffect } from 'react'
import ProTypes from 'prop-types'
import styled from 'styled-components'
import Input from '../atoms/input.js'
import Button from '../atoms/Button'
import BigHeader from '../atoms/BigHeader.js'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../theme/GlobalStyle.js'
import { theme } from '../../theme/mainTheme.js'
import { useLocation, Link } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext.js'
import { connect } from 'react-redux'
import { addItem } from '../../actions/index.js'
import { useFormik } from 'formik'
import { addItemThunk } from '../../actions/dataThunks.js'
import { useDispatch } from 'react-redux'
import { getAuth } from 'firebase/auth'
import Paragraph from '../atoms/paragraph.js'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import toolTip from '../../assets/graphs/tooltip.png'
import toolTip2 from '../../assets/graphs/tooltip2.png'
import denisToolTip from '../../assets/graphs/denistooltip.png'

const StyledWrapper = styled.form`
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	position: fixed;
	right: 0;
	top: 0;
	height: 100vh;
	padding: 0px 20px;
	width: 100vw;
	border-left: 5px solid ${({ theme, color }) => theme[color]};
	background-color: #fff;
	z-index: 21;

	transform: ${({ newItemBarState }) => (newItemBarState ? 'translateX(0px)' : 'translateX(100vw)')};
	transition: transform 0.5s;

	
	@media (min-width: 768px) {
		width: 50vw;
		padding: 0px 20px;
	}
	@media (min-width: 992px) {
		width: 50vw;
		padding: 0px 80px;
	}
`

const StyledTextArea = styled.textarea`
	border-radius: 25px;
	border: none;
	height: 30vh;
	font-weight: ${theme => theme.regular};
	background-color: ${({ theme }) => theme.grey100};
	font-size: 16px;
	margin-top: 20px;
	padding: 15px 30px;
	max-width: 100%;
	min-width: 100%;
	max-height: 50%;
	min-height: 50%;
	font-size: 1.6rem;
	font-family: 'Montserrat', sans-serif;
`

const InputWrappers = styled.div`
	display: flex;
	flex-direction: column;
	height: 60vh;
	filter: ${({ isBlurred }) => (isBlurred ? 'blur(5px)' : 'none')};
`

const InputWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	top: 20px;
`

const ToolTipIcon = styled.div`
	
	height: 25px;
	width: 25px;
	margin: 12px 0px 0px 20px;
	border: 1px solid ${({ theme }) => theme.grey100};
	background-color: ${({ theme }) => theme.grey100};
	color: gray;
	border-radius: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ToolTipWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 300px;
padding: 20px;



@media (min-width: 768px) {
		width: 400px;
	}
`

const ImgToolTip = styled.img`
margin: 20px 0px 0px 0px;
padding: auto;
width: 250px;

@media (min-width: 768px) {
		width: auto;
	}
`

const NewItemBar = ({ color, barState }) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const getId = () => `_${Math.random().toString(36).substr(2, 9)}`
	const { appState, setCurrentPage, newItemBarState, HandleNewItemBarState } = useContext(AppContext)
	setCurrentPage(location.pathname)
	const [userId, setUserId] = useState(null)

	const addItemFunction = (itemType, itemContent, userId) => {
		const authorId = userId
		const dataWithUserId = { ...itemContent, authorId }
		dispatch(addItemThunk(itemType, dataWithUserId))
	}

	const formik = useFormik({
		initialValues: {
			title: '',
			articleUrl: '',
			content: '',
		},
		onSubmit: (values, { resetForm }) => {
			const dataToSend = { ...values, id: getId(), created: new Date().getTime() }
			addItemFunction(appState, dataToSend, userId)
			HandleNewItemBarState()
			resetForm()
		},
	})

	useEffect(() => {
		const auth = getAuth()
		const user = auth.currentUser
		if (user) {
			setUserId(user.uid)
		}
	}, [])

	let placeholderText
	switch (appState) {
		case 'twitters':
			placeholderText = 'Please paste the tweet ID here'
			break
		case 'articles':
			placeholderText = 'Please paste link to article'
			break
		case 'videos':
			placeholderText = 'Paste YT video ID'
			break
	}

	function properToolTip(appState, toolTip) {
		switch (appState) {
			case 'articles':
				return (
					<ToolTipWrapper>
						<p>Add the link to the original article you want to publish.</p>
					</ToolTipWrapper>
				)

			case 'videos':
				return (
					<ToolTipWrapper>
						<p>
							Add the ID of the YouTube video - the string of characters at the very end of the URL where the original
							video was published.
						</p>
						<ImgToolTip src={toolTip} alt='How to add YouTube video?' />
					</ToolTipWrapper>
				)
			case 'twitters':
				return (
					<ToolTipWrapper>
						<p>
							Add the ID of the post published on Twitter or X.com - the string of characters at the very end of the URL where
							the original post was published.
						</p>
						
						<ImgToolTip src={toolTip2} alt='How to add twitters?' />
					</ToolTipWrapper>
				)
			default:
				// Używanie `alert` wewnątrz renderowania nie jest zalecane.
				console.log('Nie rozumiem')
				return null
		}
	}
	function properToolTip1(appState, toolTip) {
		switch (appState) {
			case 'articles':
				return (
					<ToolTipWrapper>
						<p>Add the title of the original article you are adding, or your own title.</p>
					</ToolTipWrapper>
				)
			case 'notes':
				return (
					<ToolTipWrapper>
						<p>Add the title of your note.</p>
					</ToolTipWrapper>
				)
			case 'videos':
				return (
					<ToolTipWrapper>
						<p>Add the title of the YouTube video you want to publish.</p>
					</ToolTipWrapper>
				)
			case 'twitters':
				return (
					<ToolTipWrapper>
						<p>Add the @handle of the user whose tweet you want to add.</p>
						<ImgToolTip src={denisToolTip} alt='How to add twitters?' />
					</ToolTipWrapper>
				)
			default:
				// Używanie `alert` wewnątrz renderowania nie jest zalecane.
				console.log('Nie rozumiem')
				return null
		}
	}

	return (
		<StyledWrapper color={color} newItemBarState={newItemBarState} onSubmit={formik.handleSubmit}>
			<BigHeader>Create new {appState.slice(0, -1)}</BigHeader>

			<InputWrappers isBlurred={!userId}>
				<InputWrapper>
					<Input
						id='title'
						name='title'
						type='text'
						placeholder={appState === 'twitters' ? `Twiter @handle name` : `Title of ${appState.slice(0, -1)}`}
						onChange={formik.handleChange}
						value={formik.values.title}
					/>
					<a data-tooltip-id='my-tooltip1'>
						<ToolTipIcon>?</ToolTipIcon>
					</a>
					<Tooltip id='my-tooltip1'>{properToolTip1(appState, toolTip)}</Tooltip>
				</InputWrapper>
				{(appState === 'articles' || appState === 'twitters' || appState === 'videos') && (
					<InputWrapper>
						<Input
							id='articleUrl'
							name='articleUrl'
							type='text'
							placeholder={placeholderText}
							onChange={formik.handleChange}
							value={formik.values.articleUrl}
						/>
						<a data-tooltip-id='my-tooltip'>
							<ToolTipIcon>?</ToolTipIcon>
						</a>
						<Tooltip id='my-tooltip'>{properToolTip(appState, toolTip)}</Tooltip>
					</InputWrapper>
				)}
				<StyledTextArea
					id='content'
					name='content'
					type='textarea'
					placeholder={appState === 'twitters' ? `Add your comment here` : `Content of ${appState.slice(0, -1)}`}
					onChange={formik.handleChange}
					value={formik.values.content}
				/>
			</InputWrappers>
			<Button type='submit' disabled={!userId}>
				{appState === 'articles' && 'add article'}
				{appState === 'twitters' && 'add twitter post'}
				{appState === 'notes' && 'add note'}
				{appState === 'videos' && 'add video'}
			</Button>
			{!userId && (
				<Paragraph as={Link} to='/login'>
					To add your content, you must be logged in.
				</Paragraph>
			)}
		</StyledWrapper>
	)
}

export default NewItemBar
