import React from 'react'
import styled from 'styled-components'


const LoggedParagraph = styled.div`
	font-style: normal;
	font-weight: ${({ fontweight, theme }) => fontweight || theme.light};
	font-size: ${({ fontsize }) => fontsize || '16px'};
    color: ${({ theme }) => theme.white};
	padding-top: 5px;
`

export default LoggedParagraph;