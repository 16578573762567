import React from 'react'
import styled, { withTheme } from 'styled-components'

const LoaderMain = styled.div`
	display: flex;
	width: 40vw;
	height: 40vh;
	justify-content: center;
	align-items: center;
`

const LoaderDiv = styled.div`
	border: 4px solid ${(props) => props.theme.black};
	border-radius: 50%;
	border-top: 4px solid ${({ theme, color }) => theme[color] || 'rgba(0, 0, 0, 0.1)'};
	width: 40px;
	height: 40px;
	animation: spin 2s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

const Loader = ({ themeColor }) => {
	return (
		<LoaderMain>
			<LoaderDiv color={themeColor} />
		</LoaderMain>
	)
}

export default withTheme(Loader)
