import React, { useRef } from 'react';
import styled from 'styled-components';
import Button2 from '../atoms/Button2.js';
import InputWithState from '../molecules/inputwithstate.js'
import { auth } from '../../firebase/firebase';
import { updatePassword } from 'firebase/auth';
import { showModal } from '../../actions/index.js'
import { useDispatch } from 'react-redux'

const NewPasswordInput = () => {
    const passwordInputRef = useRef();
    const dispatch = useDispatch();

    const handlePasswordChange = (e) => {
        e.preventDefault();
        const newPassword = passwordInputRef.current.value; // Używamy ref do odczytania nowego hasła

        if(auth.currentUser) {
            updatePassword(auth.currentUser, newPassword).then(() => {

                dispatch(showModal('Your password has been changed.'));

                    setTimeout(() => {
                        window.location.reload(); 
                    }, 1500)
               
            }).catch((error) => {
                alert(error.message);
            });
        }
    };

    const FormPassword = styled.form`
        display: flex;
        flex-direction: column;
        align-items: left;
    `;

    return (
        <FormPassword onSubmit={handlePasswordChange}>
            <InputWithState ref={passwordInputRef} type="password" placeholder="Add new password..." margin='10px 0px 10px 0px' />
            <Button2 type="submit" backgroundcolor='black' color='white' margin={'0px 0px 0px 0px' } width={'200px'}>Change</Button2>
        </FormPassword>
    );
};

export default NewPasswordInput;