import React, { useState } from 'react'
import { signOut } from 'firebase/auth'
import { auth } from '../../firebase/firebase'
import ButtonIcon from '../atoms/Buttonicon.js'
import logout from '../../assets/icons/logout.svg'
import ModalComponent from './modal.js'
import { useNavigate } from 'react-router-dom'

const LogoutButton = () => {
	const navigate = useNavigate()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalContent, setModalContent] = useState('')

	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				// Wylogowanie się powiodło
				setModalContent('User logged out.')
				setIsModalOpen(true)
				setTimeout(() => {
					setIsModalOpen(false) // Opcjonalnie, zamknij modal automatycznie po czasie
					navigate('/notes')
				}, 500)
			})
			.catch(error => {
				setModalContent(`Logout error: ${error.message}`)
				setIsModalOpen(true)
				setTimeout(() => {
					setIsModalOpen(false) // Opcjonalnie, zamknij modal automatycznie po czasie
				}, 2000)
			})
	}

	return (
		<>
			<ButtonIcon icon={logout} onClick={handleLogout} />
			<ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} content={modalContent} />
		</>
	)
}

export default LogoutButton
