import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

const FailurePage = () => {
	const location = useLocation()
	const data = location.state.dataToSend

	return <p>{data} - Failure</p>
}

export default FailurePage
