import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';

const SuccessPage = () => {
    const location = useLocation();
    const data = location.state.dataToSend;



	return <p>{data} - Success</p>
}

export default SuccessPage
