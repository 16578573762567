import React, { useRef } from 'react'
import styled from 'styled-components'
import Button2 from '../atoms/Button2.js'
import FileInputWithState from './filewithstate.js' // Zaimportuj FileInputWithState
import { auth, storage } from '../../firebase/firebase.js'
import { updateProfile } from 'firebase/auth'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { showModal } from '../../actions/index.js'
import { useDispatch } from 'react-redux'

const NewPhotoInput = () => {
	const fileInputRef = useRef() // Utworzenie ref do przechowywania referencji do inputu pliku
	const dispatch = useDispatch()

	const handlePhotoUpload = e => {
		e.preventDefault()
		const file = fileInputRef.current.files[0] // Bezpośrednie uzyskanie pliku z ref
		if (!file) {
			alert('Please select a file first.')
			return
		}

		const fileRef = storageRef(storage, `profilePictures/${auth.currentUser.uid}`)
		uploadBytes(fileRef, file)
			.then(() => {
				getDownloadURL(fileRef).then(url => {
					updateProfile(auth.currentUser, { photoURL: url }).then(() => {
						dispatch(showModal('Your avatar has been changed.'))

						setTimeout(() => {
							window.location.reload()
						}, 1500)
					})
				})
			})
			.catch(error => {
				dispatch(showModal('Database or authorization error: ' + error.message))

				setTimeout(() => {
					window.location.reload()
				}, 1500)
			})
	}

	const FormPhoto = styled.form`
		display: flex;
		flex-direction: column;
		align-items: left;
	`

	return (
		<FormPhoto onSubmit={handlePhotoUpload}>
			<FileInputWithState ref={fileInputRef} placeholder='Upload new photo' margin='10px 0px 10px 0px' />
			<Button2 type='submit' margin='0px 0px 0px 0px' backgroundcolor='black' color='white'>
				Change / Download
			</Button2>
		</FormPhoto>
	)
}

export default NewPhotoInput
