import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../theme/GlobalStyle.js'
import { theme } from '../theme/mainTheme.js'
import Sidebar from '../components/organisms/Sidebar.js'
import NewNameInput from '../components/molecules/newName.js'
import NewPhotoInput from '../components/molecules/newPhoto.js'
import NewPasswordInput from '../components/molecules/newPassword.js'
import Button from '../components/atoms/Button.js'
import SmallHeader from '../components/atoms/SmallHeader.js'
import BigHeader from '../components/atoms/BigHeader.js'
import Paragraph from '../components/atoms/paragraph.js'
import StyledAvatar from '../components/atoms/StyledAvatar.js'
import { Link } from 'react-router-dom'
import { auth } from '../firebase/firebase'
import { updateProfile, onAuthStateChanged } from 'firebase/auth'
import { useSelector, useDispatch } from 'react-redux'
import { fetchData } from '../actions/dataThunks.js'
import LineToRemove from '../components/molecules/lineToRemove.js'
import { Helmet } from 'react-helmet'

const Paragraph1 = styled.div`
	word-wrap: break-word; /* Zapewnia zawijanie długich słów i adresów URL */
	overflow-wrap: break-word; /* Alternatywna nazwa dla word-wrap, wspierana przez niektóre przeglądarki */
	white-space: pre-wrap; /* Umożliwia naturalne zawijanie tekstu */
`

const Mainpart = styled.div`
	display: flex;
	position: relative;
	flex-wrap: wrap;
	flex-direction: column;
	width: 50vw;
	margin: 0px 0px 0px 110px;

	@media (min-width: 768px) {
		margin-left: 180px;
		justify-content: space-between;
		flex-direction: row;
		width: 70vw;
		padding: 25px;
		border-top: 2px solid black;
	}
`

const MainpartList = styled(Mainpart)`
	@media (min-width: 768px) {
		flex-direction: column;
	}
`

const Header = styled.div`
	display: flex;
	align-items: left;
	flex-direction: column;
	margin: 0px 20px 20px 110px;
	@media (min-width: 576px) {
		flex-direction: row;
		align-items: center;
	}
	@media (min-width: 768px) {
		margin: 0px 20px 20px 180px;
	}
`

const HeaderPart = styled.div`
	margin: 20px 0px 0px 0px;
	@media (min-width: 576px) {
		margin: 20px 0px 0px 20px;
	}
`
const Part = styled.div`
	margin: 0px 0px 20px 0px;
	padding: 20px 0px 0px 0px;
	border-top: 2px solid black;

	@media (min-width: 768px) {
		border: none;
		padding: 0px 20px 0px 0px;
	}
`

const StyledBigHeader = styled(BigHeader)`
	margin-top: 10px;
`
const StyledButton = styled(Button)`
	margin: 50px 0px 0px 110px;
	background-color: ${({ theme }) => theme.black};
	color: ${({ theme }) => theme.white};

	@media (min-width: 768px) {
		margin-left: 180px;
	}
`

const UpdateProfile = () => {
	const [user, setUser] = useState(null)
	const [toDisplay, setToDisplay] = useState([])
	const navigate = useNavigate()

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, currentUser => {
			setUser(currentUser)
		})

		// Funkcja czyszcząca na wypadek, gdy komponent będzie odmontowywany
		return () => unsubscribe()
	}, [])

	const { twitters, notes, articles, videos, asktoremove } = useSelector(state => state.items)
	const dispatch = useDispatch()

	useEffect(() => {
		if (asktoremove && !asktoremove.length) {
			// Jeśli stan jest i jest pusty, pobierz dane
			dispatch(fetchData('asktoremove'))
		}
	}, [asktoremove])

	useEffect(() => {
		if (user && asktoremove.length > 0) {
			const userItems = asktoremove.filter(item => item.authorId === user.uid)
			const uniqueToDisplay = userItems.reduce((acc, current) => {
				const x = acc.find(item => item.articleId === current.articleId)
				if (!x) {
					return acc.concat([current])
				} else {
					return acc
				}
			}, [])
			setToDisplay(uniqueToDisplay)
		}
	}, [user, asktoremove])

	const leaveSettings = () => navigate('/')

	return (
		<div>
			<GlobalStyle />
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>Your account settings</title>
					<meta name='description' content={`Here you can manage your account.`} />
					<meta
						name='keywords'
						content={`San Antonio, Górnik Wałbrzych, Basketball, Jeremy Sochan, nba, scores, video, twitters`}
					/>
				</Helmet>
				<Sidebar pageType='settings' />

				<Header>
					<StyledAvatar src={user && user.photoURL} h='90px' w='90px' br='45px' rotateOnHover={false} />
					<HeaderPart>
						<SmallHeader>Welcome</SmallHeader>
						<StyledBigHeader>{user && user.displayName}</StyledBigHeader>
					</HeaderPart>
				</Header>
				<Header>
					<SmallHeader>Your account:</SmallHeader>
				</Header>
				<Mainpart>
					<Part>
						<Paragraph1>Change your account name:</Paragraph1>
						<NewNameInput></NewNameInput>
					</Part>
					<Part>
						<Paragraph>Change your avatar:</Paragraph>
						<NewPhotoInput></NewPhotoInput>
					</Part>
					<Part>
						<Paragraph>Change your account password:</Paragraph>
						<NewPasswordInput></NewPasswordInput>
					</Part>
				</Mainpart>
				<Header>
					<SmallHeader>Your requests:</SmallHeader>
				</Header>
				<MainpartList>
					{toDisplay &&
						toDisplay.map(item => (
							<LineToRemove
								cardType={item.cardType}
								authorId={item.authorId}
								askerId={item.userId}
								itemId={item.id}
								articleId={item.articleId}
							/>
						))}
				</MainpartList>
			</ThemeProvider>
		</div>
	)
}

export default UpdateProfile
