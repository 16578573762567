import React, { useEffect } from 'react'
import { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../theme/GlobalStyle.js'
import { theme } from '../../theme/mainTheme.js'
import Sidebar from '../organisms/Sidebar.js'
import BigHeader from '../atoms/BigHeader.js'
import Button from '../atoms/Button.js'
import Paragraph from '../atoms/paragraph.js'
import AddInfoSmall from '../atoms/AddInfoSmall.js'
import { AppContext } from '../../contexts/AppContext.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchData } from '../../actions/dataThunks.js'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import { removeItemThunk, addItemThunk } from '../../actions/dataThunks.js'
import Loader from '../molecules/Loader.js'
import YouTubeScreen from '../organisms/YouTubeScreen.js'
import { app } from '../../firebase/firebase.js'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { getDatabase, ref as dbRef, get } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { Helmet } from 'react-helmet';

const ContentWrapper = styled.div`
	margin-left: 100px;
	margin-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
	width: 65vw;

	@media (min-width: 576px) {
		margin-left: 120px;
	}

	@media (min-width: 768px) {
		margin-left: 180px;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	@media (min-width: 992px) {
		margin-left: 200px;
	}
`
const RemoveNote = styled.div`
	text-decoration: underline;
`

const Content = styled.div`
	margin: 20px 0px 0px 0px;
	
`
const ContentOpen = styled.div`
	margin: 30px 10px 40px 0px;
`

const ContentHeader = styled.div`
	position: relative;
	display: flex;
`

const TitleHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
const PhotoHeader = styled.div`
	top: 0px;
	left: 0px;
	display: flex;
	margin-bottom: 20px;

	@media (min-width: 768px) {
		top: -45px;
		left: 410px;
	}
`

const YTWrapper = styled.div`
	margin-top: 20px;
	width: 900px;
	height: 506px;
	overflow: hidden;
`

const StyledAvatar = styled.img`
	width: 70px;
	height: 70px;
	border-radius: 40px;
	right: 25px;
	border: none;
	background-color: transparent;

	@media (min-width: 768px) {
		width: 100px;
		height: 100px;
		border-radius: 50px;
	}
`

const DetailsTemplate = removeItemFunction => {
	//stan obsługujący loader
	const [isLoading, setIsLoading] = useState(true)
	const [authorPhoto, setAuthorPhoto] = useState('')
	const [authorName, setAuthorName] = useState('')
	const [item, setItem] = useState(null)
	const [userId, setUserId] = useState(null)


	const location = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()


	const { twitters, notes, articles, videos } = useSelector(state => state.items)

	const { appState, setCurrentPage, appNumber, setCurrentPageNumber } = useContext(AppContext)

	setCurrentPage(location.pathname)
	setCurrentPageNumber(location.pathname)

	const buttonProps = {
		twitters: appState === 'twitters',
		articles: appState === 'articles',
		notes: appState === 'notes',
		videos: appState === 'videos',
	}

	const cutTextAtWordBoundary = (text, maxLength) => {
		if (text.length <= maxLength) {
			return text
		}

		const subString = text.substr(0, maxLength)
		const lastSpaceIndex = subString.lastIndexOf(' ')

		if (lastSpaceIndex === -1) {
			// Nie ma spacji, zwracamy cały ciąg
			return subString
		} else {
			// Zwracamy tekst do ostatniej spacji
			return subString.substr(0, lastSpaceIndex) + '...'
		}
	}

	useEffect(() => {
		const isStateEmpty = !twitters.length && !notes.length && !articles.length && !videos.length

		if (isStateEmpty) {
			// Jeśli stan jest pusty, pobierz dane
			dispatch(fetchData(appState))
		}

		const items = { twitters, notes, articles, videos }[appState] || []
		const newItem = items.filter(obiekt => obiekt.id === appNumber)[0]
		setItem(newItem)
	}, [appNumber, appState, twitters, notes, articles, videos])

	useEffect(() => {
		const auth = getAuth()
		const user = auth.currentUser
		if (user) {
			setUserId(user.uid)
		}
	}, [])

	useEffect(() => {
		if (item && item.authorId) {
			// Pobieranie zdjęcia autora
			const storage = getStorage(app)
			const userPhotoRef = ref(storage, `profilePictures/${item.authorId}`)
			getDownloadURL(userPhotoRef)
				.then(url => {
					setAuthorPhoto(url)
				})
				.catch(error => {
					// Ustawienie domyślnego zdjęcia, jeśli nie uda się pobrać
					setAuthorPhoto(
						'https://firebasestorage.googleapis.com/v0/b/favnote-a09d2.appspot.com/o/profilePictures%2FR4498siYdXSYWt9rUk8oRGdvTso1?alt=media&token=b5021fbf-58d1-439b-ac6d-ff535bde71ed'
					)
				})

			// Nowy kod do pobierania imienia autora z Realtime Database
			const database = getDatabase(app)
			const nameRef = dbRef(database, `names/${item.authorId}`)
			get(nameRef)
				.then(snapshot => {
					if (snapshot.exists()) {
						setAuthorName(snapshot.val().name) // Ustawienie imienia autora
					} else {
						console.log('No data available for author name.')
					}
				})
				.catch(error => {
					console.error(error)
				})
		}
	}, [item])

	

	let linkContentToDisplay

	

	switch (appState) {
		case 'twitters':
			linkContentToDisplay = item && item.articleUrl !== null && <TwitterTweetEmbed tweetId={item.articleUrl} />
			break
		case 'articles':
			linkContentToDisplay = item && item.articleUrl && (
				<a href={item.articleUrl} target='_blank' rel='noopener noreferrer'>
					{appState === 'notes' && ''}
					{appState === 'articles' && 'OPEN THIS ARTICLE'}
				</a>
			)

			break
		case 'videos':
			linkContentToDisplay = item && item.articleUrl && <YouTubeScreen data={item.articleUrl} />
			break
		case 'notes':
			linkContentToDisplay = item && item.articleUrl && <YouTubeScreen data={item.articleUrl} />
			break
		default:
			linkContentToDisplay = <p>'fffff'</p>
	}

	if (!item) {
		return <Loader themeColor={appState} />
	} else {
		const itemDate = new Date(item.created)
		const formattedDate = itemDate.toLocaleDateString('pl-PL')
		return (
			<div>
				<GlobalStyle />
				<ThemeProvider theme={theme}>
				<Helmet>
          <title>{item.title}</title>
		  <meta name="description" content={`${cutTextAtWordBoundary(item.content, 160)}`} />
          <meta name="keywords" content={`${appState}, San Antonio, Górnik Wałbrzych, Basketball, Jeremy Sochan, nba, scores, video, twitters`} />
        </Helmet>
					<Sidebar pageType={appState} />

					<ContentWrapper isTwitter={appState}>
						<ContentHeader>
							<TitleHeader>
								<PhotoHeader>
									<StyledAvatar src={authorPhoto} alt={'Author'} />
								</PhotoHeader>
								<BigHeader>{item.title}</BigHeader>

								<AddInfoSmall>
									Created - {formattedDate} - by {authorName}
								</AddInfoSmall>
							</TitleHeader>
						</ContentHeader>

						<Content>
							<Paragraph>{item.content}</Paragraph>
						</Content>

						<ContentOpen>{item && item.articleUrl !== null && linkContentToDisplay}</ContentOpen>

						<Link to='/'>
							<Button {...buttonProps}>Go Back</Button>
						</Link>
						<RemoveNote>
							<AddInfoSmall>
							{userId && 	item.authorId === userId ? 
								<Button
									onClick={event => {
										event.stopPropagation()
										dispatch(removeItemThunk(appState, appNumber))
										navigate(`/${appState}`)
									}}>
									{appState === 'twitters' && 'remove this twitt'}
									{appState === 'notes' && 'remove this note'}
									{appState === 'articles' && 'remove this article'}
									{appState === 'videos' && 'remove this video'}
								</Button> : <Button
					onClick={event => {
						event.stopPropagation()
						const articleId = item.id;
						const authorId = item.authorId;
						const cardType = appState;
						dispatch(addItemThunk(`asktoremove`, {authorId, userId, cardType, articleId}))
					}}>
					ask to remove
				</Button> }




							
							</AddInfoSmall>
						</RemoveNote>
					</ContentWrapper>
				</ThemeProvider>
			</div>
		)
	}
}

export default DetailsTemplate
