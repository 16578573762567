import styled, { css } from 'styled-components'

const Button1 = styled.button`
	border: none;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 50px;
	width: ${({ width }) => width || '80%'};
	height: ${({ height }) => height || '47px'};
	font-size: ${({ fontsize }) => fontsize || '10px'};
	background-color: ${({ background }) => background || 'black'};
	color: ${({ color }) => color || 'white'};
	cursor: pointer;

	

	${({ tools }) =>
		tools &&
		css`
			font-weight: 100;
			text-transform: uppercase;
			border-radius: 50px;
			width: ${({ width }) => width || '100px'};
			height: ${({ height }) => height || '20px'};
			font-size: ${({ fontsize }) => fontsize || '10px'};
			background-color: ${({ isActive, theme }) => isActive ? 'black' : theme.grey200};
			color: ${({ isActive }) => isActive ? 'white' : 'black'};
			cursor: pointer;
			margin-right: 10px;

		
		`}

	/* @media (min-width: 768px) {
		width: ${({ width }) => width || '80%'};
		font-size: ${({ fontsize }) => fontsize || '16px'};
	} */
`

export default Button1
